<template>
    <div class="page-prepay-mobile-preactivation">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <h1 class="heading">Preactivation</h1>

                    <app-tabs v-model="tab" :tabs="tabs" :dropdownOnTablet="true" />

                    <router-view></router-view>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import appTabs from '@/components/app-tabs'

export default {
    components: {
        appTabs,
    },

    computed: {
        tabs() {
            const tabs = [
                {
                    id:    'prepay-mobile-preactivation-reserve',
                    name:  'prepay-mobile-preactivation-reserve',
                    title: 'Reserve IMSIs + MSISDNs',
                },
                {
                    id:    'prepay-mobile-preactivation-previous-reservations',
                    name:  'prepay-mobile-preactivation-previous-reservations',
                    title: 'Previous Reservations',
                },
                {
                    id:    'prepay-mobile-preactivation-preactivation',
                    name:  'prepay-mobile-preactivation-preactivation',
                    title: 'Preactivation',
                },
            ]

            return tabs
        },

        tab: {
            get() {
                const tab = this.tabs.find(tab => this.$route.name == tab.name)

                return tab
            },

            set(tab) {
                this.$router.push({ name: tab.name, replace: true })
            },
        },
    },
}
</script>

<style lang="scss">
.page-prepay-mobile-preactivation {
    padding-bottom: 80px;

    h1 {
        &.heading {
            margin-bottom: 40px;
        }
    }

    .app-tabs {
        margin-bottom: 44px;
    }
}

@media (max-width: $mobile-size) {
    .page-prepay-mobile-preactivation {
        padding-bottom: 64px;

        h1 {
            &.heading {
                margin-bottom: 32px;
            }
        }

        .app-tabs {
            margin-bottom: 16px;
        }
    }
}
</style>